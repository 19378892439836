<template>
  <div class="main">
    <div class="container">
      <h1>編輯個人資訊</h1>
      <div class="formBox">
        <div class="formGroup" v-if="User">
          <div class="formRow" v-if="false">
            <h3>帳號</h3>
            <input id="account" type="text" name="" value="" /><span
              >請以身分證字號爲帳號 / 團體請用統一編號</span
            >
          </div>
          <div class="formRow">
            <h3>Email</h3>
            <input
              v-model="User.Email"
              required
              id="email"
              type="email"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>密碼</h3>
            <input
              v-model="User.Password"
              id="password1"
              type="password"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>確認密碼</h3>
            <input
              v-model="User.Password2"
              id="password2"
              type="password"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>連絡電話(手機)</h3>
            <input
              v-model="User.Mobile"
              required
              id="mobile"
              type="tel"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>連絡電話(公司)</h3>
            <input
              v-model="User.CompanyTelephone"
              :required="User.UserType == 4"
              id="phone_company"
              type="tel"
              name=""
              value=""
            /><span>團體必填，其他選填</span>
          </div>
          <div class="formRow">
            <h3>現職</h3>
            <input
              v-model="User.Job"
              :required="User.UserType < 4"
              id="job_title"
              type="text"
              name=""
              value=""
            /><span>(公司全名+職稱)EX:臺灣體育運動管理學會專員</span>
          </div>
          <div class="formRow">
            <h3>學歷</h3>
            <input
              v-model="User.School"
              :required="User.UserType < 4"
              id="education"
              type="text"
              name=""
              value=""
            /><span>(學校全名+系所全名+級別)EX:XX大學運動休閒系碩士</span>
          </div>
          <div class="formRow">
            <h3>通訊地址</h3>
            <input
              v-model="User.ContectAddress"
              required
              id="mobile"
              type="tel"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>Line ID</h3>
            <input
              v-model="User.LineID"
              required
              id="mobile"
              type="tel"
              name=""
              value=""
            />
          </div>
        </div>
        <div class="submitBtn" @click="submit">
          <a href="javascript:void(0);">確認修改</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      User: null,
    };
  },
  mounted() {
    this.setBase("register");
    this.load();
  },
  methods: {
    async load() {
      var result = await this.$api.getMe();
      result.Password = "";
      result.Password2 = "";
      this.User = result;
      this.loadCustomJs();
    },
    async submit() {
      if (!this.validate(".formBox")) {
        return;
      }
      if (this.User.Password != this.User.Password2) {
        alert("兩次密碼不一致，請重新輸入！");
        return;
      }
      try {
        var result = await this.$api.updateMe(this.User);
        alert("儲存成功。");
      } catch (e) {
          if(e.Status == 4){
              alert('此帳號已註冊過');
          }
      }
    },
  },
  watch: {
    $route(to, from) {
      this.load();
    },
  },
};
</script>